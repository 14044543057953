<template>
  <div>
    <!-- #page-header -->
    <div
      id="page-header"
      class="cover"
      data-image-src="assets/img/bg/zlavy.jpg"
    >
      <div class="container">
        <div class="-wrapper">
          <div class="_inner discounts">
            <h1 v-translate>Partnerské zľavy</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-header -->

    <!-- .blocks -->
    <div class="blocks">
      <div class="container">
        <!-- .block -->
        <!-- <section class="block -shadow">
          <header class="text-center">
            <h2 class="alt -black" v-translate>VPS a Cloud</h2>
            <p v-translate>
              Naša kalkulačka vám vypočíta výnos pri rôznych obratoch. Čím viac
              predáte webhostingov, serverov či cloudových služieb, tým väčšie
              zľavy za nákup od nás máte, a tým väčší bude aj váš výnos.
            </p>
          </header>

           .calculator 
          <div class="calculator">

            <div class="-top">
              <form>
                <div class="calc">
                  <div class="-part">

                    <label v-translate>Váš mesačný obrat za webhosting</label>
                    <div class="input-row">
                      <span>&euro;</span>
                      <input type="text" v-model="hosting" value="0">
                    </div>

                  </div>
                  <div class="-part">

                    <label v-translate>Váš mesačný obrat za VPS a cloud</label>
                    <div class="input-row">
                      <span>&euro;</span>
                      <input type="text" v-model="vps" value="0">
                    </div>

                  </div>
                  <div class="-part">

                    <input type="submit" v-bind:value="$gettext('Vypočítaj')" @click="calculate">

                  </div>
                </div>
              </form>
            </div>
            <div v-bind:class="{ hidden: isHidden, '-bottom': 1 }">

              <div class="sign">=</div>

              <div class="results">
                <div class="res"><span v-translate>Pri takomto obrate od nás dostanete</span> <br/> <strong><span>{{ zlava_hosting_text }}</span>% <span v-translate>zľavu na webhostingové služby</span></strong>&nbsp;<span>a</span>&nbsp;<strong><span>{{ zlava_vps_text }}</span>% <span v-translate>zľavu na VPS a Cloud</span></strong>
                  <div class="result"><span v-translate>čím zarobíte</span> <span> {{ zarobok }} </span> €</div>
                </div>
              </div>

            </div>

            <div class="bg"><img src="assets/img/icons/calc_bg.svg" alt=""></div>

          </div>
           /.calculator 
        </section>..>
        /.block -->

        <!-- .block -->
        <section class="block noPadding extra-margin">
          <!-- ._header -->
          <header class="_header text-center -alt">
            <h2 v-translate>Čo môžete v rámci Webpartnera poskytovať</h2>
          </header>
          <!-- /._header -->

          <!-- .services -->
          <div class="services">
            <div class="row">
              <div class="col-md-6">
                <p>
                  <strong v-translate
                    >Fungujeme ako webhostingový veľkoobchod - nakúpite u nás
                    komponenty, z ktorých si vyskladáte vlastné webhostingové
                    programy.
                  </strong>
                </p>
                <p>
                  <strong v-translate
                    >Poskytujeme 3 základné služby navrhnuté tak, aby Vám
                    umožnili konkurovať čomukoľvek, čo je dnes poskytované na
                    slovenskom a českom trhu.</strong
                  >
                </p>
              </div>
              <div class="col-md-6">
                <div class="services-icons">
                  <ul>
                    <li>
                      <div class="icon-box">
                        <div class="ico">
                          <img src="assets/img/icons/ico_cloud.svg" alt="" />
                        </div>
                        <span v-translate>VPS a Cloud</span>
                      </div>
                    </li>
                    <li>
                      <div class="icon-box">
                        <div class="ico">
                          <img
                            src="assets/img/icons/ico_webhosting.svg"
                            alt=""
                          />
                        </div>
                        <span v-translate>Webhosting</span>
                      </div>
                    </li>
                    <li>
                      <div class="icon-box">
                        <div class="ico">
                          <img
                            src="assets/img/icons/ico_zakaznici.svg"
                            alt=""
                          />
                        </div>
                        <span v-translate>Registrácia domén</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- /.services -->

          <!-- .text-part -->
          <div class="text-part text-center">
            <p>
              <i v-translate
                >Ceny našich hostingových produktov sa pohybujú od 1,99€/
                mesiac, virtuálnych serverov a cloudu od 8€ / mesiac. Vy si
                samozrejme môžete produktové balíky a ceny prispôsobiť podľa
                seba.</i
              >
            </p>
          </div>
          <!-- /.text-part -->
        </section>
        <!-- /.block -->

        <!-- .block -->
        <section class="block noPadding">
          <!-- .price-boxes -->
          <div class="price-boxes">
            <div class="row">
              <div class="col-md-6">
                <!-- .price-box -->
                <div class="price-box">
                  <h3 v-translate>Webhosting, Domains</h3>
                  <!-- ,Domains-->

                  <!-- .price-table -->
                  <div class="price-table jsHeight">
                    <!-- .table-head -->
                    <div class="table-head">
                      <div class="table-row font-row">
                        <div class="table-col text-left">
                          <span class="no-padding" v-translate
                            >Priemerný <br />mesačný obrat (&euro;)</span
                          >
                        </div>
                        <div class="table-col text-center flex-column">
                          <span class="no-padding" v-translate>Zľava</span>
                          <div v-translate>Webhosting</div>
                        </div>
                        <div class="table-col text-center flex-column">
                          <span class="no-padding flex-center" v-translate
                            >Zľava</span
                          >
                          <div v-translate>Domény</div>
                        </div>
                      </div>
                    </div>
                    <!-- /.table-head -->

                    <!-- .table-body -->
                    <div class="table-body">
                      <div class="table-row">
                        <div class="table-col">35 - 59.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #2a1002">15 %</div>
                          <div class="per" style="color: #2a1002">5 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">60 - 149.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #5e2405">18 %</div>
                          <div class="per" style="color: #5e2405">7 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">150 - 299.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #913807">22 %</div>
                          <div class="per" style="color: #913807">10 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">300 - 499.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #c34c0a">25 %</div>
                          <div class="per" style="color: #c34c0a">13 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">500 +</div>
                        <div class="table-col">
                          <div class="per" style="color: #e5590c">30 %</div>
                          <div class="per" style="color: #e5590c">15 %</div>
                        </div>
                      </div>
                    </div>
                    <!-- /.table-head -->
                  </div>
                  <!-- /.price-table -->
                </div>
                <!-- /.price-box -->
              </div>
              <div class="col-md-6">
                <!-- .price-box -->
                <div class="price-box">
                  <h3 v-translate>VPS, Cloud</h3>

                  <!-- .price-table -->
                  <div class="price-table jsHeight">
                    <!-- .table-head -->
                    <div class="table-head">
                      <div class="table-row font-row">
                        <div class="table-col text-center">
                          <span v-translate
                            >Priemerný mesačný obrat (&euro;)</span
                          >
                        </div>
                        <div class="table-col">
                          <span v-translate>Zľava</span>
                        </div>
                      </div>
                    </div>
                    <!-- /.table-head -->

                    <!-- .table-body -->
                    <div class="table-body">
                      <div class="table-row">
                        <div class="table-col">100 - 249.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #2a1002">7 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">250 - 499.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #913807">10 %</div>
                        </div>
                      </div>

                      <div class="table-row">
                        <div class="table-col">500 - 999.9</div>
                        <div class="table-col">
                          <div class="per" style="color: #e5590c">12 %</div>
                        </div>
                      </div>
                      <div class="table-row">
                        <div class="table-col">1000 +</div>
                        <div class="table-col">
                          <div class="per" style="color: #e5590c">15 %</div>
                        </div>
                      </div>
                    </div>
                    <!-- /.table-head -->
                  </div>
                  <!-- /.price-table -->
                </div>
                <!-- /.price-box -->
              </div>
            </div>
          </div>
          <!-- /.price-boxes -->

          <ul class="links">
            <router-link :to="{ name: 'registration' }" tag="li"
              ><a class="bttn -small -button" target="_self" v-translate
                >stať sa partnerom</a
              ></router-link
            >
          </ul>
        </section>
        <!-- /.block -->
      </div>
    </div>
    <!-- /.blocks -->
  </div>
</template>

<style>
.links li a.-button {
  margin-bottom: 2px;
}
</style>

<script>
export default {
  name: "discounts",
  metaInfo() {
    return {
      title: this.$gettext("discounts_title"),
      titleTemplate: "%s | Webpartner",
      meta: [
        {
          name: "description",
          content: this.$gettext("discounts_meta_description"),
        },
      ],
      htmlAttrs: {
        lang: "sk-SK",
        amp: undefined,
      },
      link: [
        { rel: "canonical", href: "https://webpartner.sk/zlavy-pre-partnerov" },
      ],
    };
  },
  data: function () {
    return {
      hosting: "",
      vps: "",
      zlava_hosting: 0.15,
      zlava_hosting_text: 15,
      zlava_vps: 0.07,
      zlava_vps_text: 7,
      zarobok: 0,
      isHidden: true,
    };
  },
  methods: {
    getDiscountHosting: function (price) {
      if (price <= 25) {
        return 0.15;
      } else if (price <= 50) {
        return 0.2;
      } else if (price <= 150) {
        return 0.25;
      } else if (price <= 300) {
        return 0.3;
      } else if (price <= 450) {
        return 0.35;
      }
      return 0.4;
    },
    getDiscountVps: function (price) {
      if (price <= 250) {
        return 0.07;
      } else if (price <= 500) {
        return 0.1;
      } else if (price <= 1000) {
        return 0.12;
      }
      return 0.15;
    },
    formatPrice(value) {
      if (value == 0) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    calculate: function (event) {
      event.preventDefault();
      if (
        null == this.hosting ||
        !(!isNaN(parseFloat(this.hosting)) && isFinite(this.hosting))
      ) {
        this.hosting = 0;
      }
      if (
        null == this.vps ||
        !(!isNaN(parseFloat(this.vps)) && isFinite(this.vps))
      ) {
        this.vps = 0;
      }
      this.zlava_hosting = this.getDiscountHosting(this.hosting);
      this.zlava_hosting_text = Math.round(
        this.getDiscountHosting(this.hosting) * 100
      );
      this.zlava_vps = this.getDiscountVps(this.vps);
      this.zlava_vps_text = Math.round(this.getDiscountVps(this.vps) * 100);

      this.zarobok = this.formatPrice(
        Math.round(
          (this.hosting * this.zlava_hosting + this.vps * this.zlava_vps) * 100
        ) / 100
      );
      this.isHidden = false;

      return;
    },
  },
};
</script>
<style scoped >
.font-row {
  display: flex !important;
  font-size: 1.5em;
  padding: 0;
  justify-content: space-between;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.no-padding {
  padding: 0 !important;
}
</style>